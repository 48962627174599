import React from "react";

function Footer() {
  return (
    <footer>
      <i className="fa-regular fa-copyright"></i>Copy All Right Reserved by
      Bibek!
    </footer>
  );
}

export default Footer;
